import { errorImagePath } from "constants/file";
import { useCallback, useEffect, useRef } from "react";
import { callMountEvent, callUnMountEvent } from "utils/event-render";

const useWatchingRender = (pageId: string) => {
  const isUnMountRef = useRef(false);
  const observerRef = useRef<MutationObserver>();
  const resolveRef = useRef<any>();
  const requestAnm = useRef<any>();

  useEffect(() => {
    return () => {
      isUnMountRef.current = true;
      observerRef.current?.disconnect();
      resolveRef.current?.();
      callUnMountEvent(pageId);
      cancelAnimationFrame(requestAnm.current);
    };
  }, []);

  const setRef = useCallback(
    async (ref: HTMLElement) => {
      // render complete
      let loadedImagesCount = 0;
      // waiting render tag image
      const totalLoadingTags = Array.from(
        ref.getElementsByClassName("chakra-spinner")
      );

      if (totalLoadingTags.length) {
        await new Promise((resolve) => {
          resolveRef.current = resolve;
          observerRef.current = new MutationObserver((mutations) => {
            const hasRemoveNode = mutations.some(
              (mutation) => mutation.removedNodes?.length
            );

            if (hasRemoveNode) {
              const loadingTags = Array.from(
                ref.getElementsByClassName("chakra-spinner")
              );
              if (!loadingTags.length) {
                resolve(true);
                observerRef.current?.disconnect();
              }
            }
          });
          observerRef.current?.observe(ref, {
            childList: true,
            subtree: true,
          });
        });
      }

      if (isUnMountRef.current) {
        return;
      }
      // waiting load image and render image
      const images = [].slice.call(ref.getElementsByTagName("img"));
      // check all image is loaded
      async function checkAllImagesLoaded() {
        if (!ref) return;

        if (loadedImagesCount === images.length) {
          // waiting all image is rendered
          await new Promise((resolve) => {
            const cb = () => {
              const isRenderAll = images.every((image: HTMLImageElement) => {
                const { naturalWidth, naturalHeight } = image;
                const hasError = image.getAttribute("data-error") === "true";
                const isNotLoaded = !naturalWidth && !naturalHeight;

                return (
                  hasError || isNotLoaded || (naturalWidth && naturalHeight)
                );
              });

              if (isRenderAll) {
                resolve(true);
              } else {
                requestAnm.current = requestAnimationFrame(cb);
              }
            };
            requestAnm.current = requestAnimationFrame(cb);
          });

          callMountEvent(pageId, ref);
        }
      }
      function subscribe() {
        loadedImagesCount++;
        checkAllImagesLoaded();
      }
      function subscribeImageError(e: ErrorEvent) {
        const imageError = e.target as HTMLImageElement;
        if (imageError) {
          imageError.setAttribute("data-error", "true");
          imageError.src = errorImagePath;
        }

        subscribe();
      }

      images.forEach((img: HTMLImageElement) => {
        if (img.complete) {
          subscribe();
        } else {
          img.addEventListener("load", subscribe);
          img.addEventListener("error", subscribeImageError);
        }
      });

      checkAllImagesLoaded();

      return () => {
        images.forEach((img: any) => {
          img.removeEventListener("load", subscribe);
          img.removeEventListener("error", subscribeImageError);
        });
        callUnMountEvent(pageId);
        resolveRef.current?.();
        cancelAnimationFrame(requestAnm.current);
      };
    },
    [pageId]
  );

  return setRef;
};

export default useWatchingRender;
