import { forgeApi } from "apiClient/v2";
import { getForgeData } from "apiClient/v2/s3Api";
import { ItemBIMType, StatusType } from "constants/enum";
import { LEVEL_ALL, LEVEL_OTHER } from "constants/forge";
import { FORGE_DATA_FOLDER_PATH } from "constants/s3";
import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import { Level, Sheet, Vector3 } from "interfaces/models";
import { ApiResponse } from "interfaces/models/api";
import { Space } from "interfaces/models/area";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { DerivativesReq } from "interfaces/models/derivatives";

import { FamilyInstance } from "interfaces/models/familyInstance";
import { ungzip } from "pako";
import store from "redux/store";
import { axiosECS } from "services/baseAxios";
import { getBimFileInfo } from "utils/bim";
import { wait } from "utils/common";
import { downloadFileFromS3 } from "utils/download-multipart";
import { logDev, logError } from "utils/logs";
import { uploadMultipartToS3 } from "utils/upload-multipart";
import { getCurrentViewer } from ".";

export let ___mapExternalId: { [key: string]: number };

export let __familyInstances: {
  [key: string]: FamilyInstanceDTO;
} = {};

export const setMapExternalId = (value: any) => {
  ___mapExternalId = value;
};

export const clearAllData = () => {
  setMapExternalId({});
  setFamilyInstances({});
};

export const setFamilyInstances = (value: any) => {
  __familyInstances = value;
};

const getDbIdToIndex = () => {
  const viewer = getCurrentViewer();
  if (!viewer) {
    return {};
  }
  const instanceTree = viewer.model.getData().instanceTree;

  return instanceTree?.nodeAccess?.dbIdToIndex || {};
};

export const covertToDbIds = (
  ids?: number | string | number[] | string[] | undefined
) => {
  if (typeof ids === "undefined") {
    return [];
  }
  if (typeof ids === "number") {
    return [ids];
  }
  if (typeof ids === "string") {
    const dbId = getDbIdByExternalId(ids);

    return [dbId];
  }
  if (Array.isArray(ids)) {
    return ids.map((id) => {
      if (typeof id === "number") {
        return id;
      }

      return getDbIdByExternalId(id);
    });
  }

  return [] as number[];
};

export const getSelectMapExternalId2DbId = async (
  externalIds: string[]
): Promise<{ [key: string]: number }> => {
  const viewer = getCurrentViewer();
  if (!viewer) {
    return {};
  }
  try {
    const filter = externalIds.reduce((prev, cr) => {
      //@ts-ignore
      prev[cr] = true;

      return prev;
    }, {});
    const result = await viewer.model
      .getPropertyDb()
      .executeUserFunction(function userFunction(pdb, filter) {
        return pdb.getExternalIdMapping(filter);
      }, filter);

    return result;
  } catch (err) {
    return {};
  }
};

export const traverseDbId = (cb: (dbId: number) => void) => {
  const dbIdToIndex = getDbIdToIndex();
  for (const dbId in dbIdToIndex) {
    const id = Number(dbId);
    if (id === 0) {
      continue;
    }
    cb(id);
  }
};

export const getDbIdByExternalId = (externalId?: string) => {
  if (!___mapExternalId || !externalId) {
    return NaN;
  }

  return Number(___mapExternalId[externalId]);
};

export const getFamilyInstancesFromS3 = async ({
  bimFileId,
  version,
  shouldCache,
}: {
  bimFileId: string;
  version?: string;
  shouldCache?: boolean;
}): Promise<
  | { familyInstances: { [key: string]: FamilyInstanceDTO }; levels: Level[] }
  | undefined
> => {
  if (!version) {
    return;
  }

  const arrayBuffer = await downloadFileFromS3({
    filePath: FORGE_DATA_FOLDER_PATH,
    fileName: `f-data-${encodeURIComponent(bimFileId)}-v${version}.json`,
    shouldCache,
  });

  let textData = "";
  try {
    textData = ungzip(arrayBuffer, { to: "string" });
  } catch (err) {
    textData = new TextDecoder().decode(arrayBuffer);
  }

  // decompress data
  try {
    return JSON.parse(textData);
  } catch (err) {
    return;
  }
};

export const uploadFamilyInstancesToS3 = async ({
  bimFileId,
  version,
  levels,
  familyInstances,
}: {
  bimFileId: string;
  levels: Level[];
  familyInstances: { [key: string]: FamilyInstance };
  version: string;
}): Promise<boolean> => {
  const uploaded = await uploadMultipartToS3({
    fileData: { levels, familyInstances },
    filePath: FORGE_DATA_FOLDER_PATH,
    fileName: `f-data-${encodeURIComponent(bimFileId)}-v${version}.json`,
  });

  return uploaded;
};

export const transformLevelByAecDataAndDerivative = async ({
  projectId,
  versionId,
}: {
  projectId: string;
  versionId: string;
}) => {
  const { data: derivativeData } = await forgeApi.getDerivativesByVersionId({
    projectId: projectId || "",
    versionId: encodeURIComponent(versionId),
  });
  const aecData: any = await getAECData(versionId);
  const levels: Level[] = [];
  const levelsAceFilter: any[] = [];

  const derivatives = derivativeData.children || [];
  let derivativesLevels3D = derivatives.filter(
    (item: any) =>
      item.hasThumbnail === "true" &&
      item.role === "3d" &&
      item.type === ItemBIMType.GEOMETRY
  );

  // support compare 1FL with 1F and PIT FL with PITFL
  const checkSameLevelByNormalize = (
    level3DName: string,
    levelAecName: string
  ) => {
    const normalizeLevelName = (levelName: string) => {
      return levelName.replace(/\s/g, "").replace(/[l|L]$/, "");
    };

    return normalizeLevelName(level3DName) === normalizeLevelName(levelAecName);
  };

  aecData?.levels?.forEach((level: any) => {
    const levelAecName = level?.name;
    if (!levelAecName) {
      return;
    }
    const derivative = derivativesLevels3D.find((item: any) => {
      const splitNames = item?.name?.split("_");
      // if level name from 3d view not match rules XX_[LEVEL_NAME]_XX then return false
      if (splitNames?.length < 3) {
        return false;
      }
      const level3DName = splitNames?.slice(1, -1)?.join("_");
      const isSameLevelWithoutNormalize = level3DName === levelAecName;
      const iSameLevelByNormalize = checkSameLevelByNormalize(
        level3DName,
        levelAecName
      );
      const isSameLevel = isSameLevelWithoutNormalize || iSameLevelByNormalize;
      if (isSameLevel) {
        derivativesLevels3D = derivativesLevels3D.filter(
          (i: any) => i.guid !== item.guid
        );
      }

      return isSameLevel;
    });
    if (derivative) {
      levelsAceFilter.push(level);
      levels.push({
        guid: String(derivative.guid),
        label: String(level.name),
        sheets: [],
        zMin: level.elevation,
        zMax: level.elevation + level.height,
      });
    }
  });

  return { levels, levelsAceFilter };
};

export const getLevelsData = async ({
  projectId,
  versionId,
}: DerivativesReq) => {
  const optionAll: Level = { ...LEVEL_ALL };
  if (!projectId || !versionId) return [optionAll];
  const { levels } = await transformLevelByAecDataAndDerivative({
    projectId,
    versionId,
  });
  const optionOther: Level = { ...LEVEL_OTHER };

  return [optionAll, ...levels, optionOther];
};

export const getViewableProperties = async ({
  bimFileId,
  version,
  level,
  keys,
}: {
  bimFileId: string;
  version: string;
  level: { guid: string; name: string };
  keys?: string[];
}): Promise<
  ApiResponse<{
    total: number;
    data: {
      objectid: string;
      name: string;
      externalId: string;
      dbId: number;
      properties: { [key: string]: any };
    }[];
  }>
> => {
  return axiosECS.post(
    `/v1/forge/projects/null/bims/version/${encodeURIComponent(
      `${bimFileId}?version=${version}`
    )}/properties`,
    {
      level,
      keys: keys || [
        "Reference Level",
        "Level",
        "System Name",
        "記号",
        "System Type",
        "Type Name",
        "タイプ名",
        "ファンの種類",
        "積算_施工区分",
        "符号",
        "形式",
        "Size",
        "サイズ",
        "Design Option",
        "ダクト径_半径",
        "風量",
        "デザイン オプション",
        "開口率",
        "面風速",
      ],
    }
  );
};

export const getSheetsData = async ({
  projectId,
  versionId,
}: DerivativesReq) => {
  let sheets: Sheet[] = [];
  if (!projectId || !versionId) return sheets;
  const { data: res } = await forgeApi.getDerivativesByVersionId({
    projectId: projectId || "",
    versionId: encodeURIComponent(versionId),
  });
  const aecData: any = await getAECData(decodeURIComponent(versionId));

  if (res.children?.length) {
    sheets = res.children
      .filter(
        (item: any) =>
          item.status === StatusType.SUCCESS &&
          item.hasThumbnail === "true" &&
          item.role === "2d" &&
          item.type === ItemBIMType.GEOMETRY
      )
      .map(
        (item: any) =>
          ({
            guid: item.guid,
            name: item.name,
            isMissingViewport: !aecData.viewports.find(
              (viewport: any) => viewport.sheetGuid === item.guid
            ),
          } as Sheet)
      );
  }

  return sheets;
};

export const checkIsDiffDataProjectVersion = (
  dataProjectDetail: DataProjectModel
) => {
  const urn = decodeURIComponent(dataProjectDetail?.defaultBimPathId || "")
    ?.split("/")
    .pop();
  const prevUrn = decodeURIComponent(
    dataProjectDetail?.prevDefaultBimPathId || ""
  )
    ?.split("/")
    .pop();

  if (!prevUrn || !urn) {
    return false;
  }

  const { version: currentForgeVersion, bimFileId: currentBimFileId } =
    getBimFileInfo(urn || "");
  const { version: prevForgeVersion, bimFileId: prevBimFileId } =
    getBimFileInfo(prevUrn || "");

  return (
    currentForgeVersion !== prevForgeVersion &&
    currentBimFileId === prevBimFileId
  );
};

export const getAllDbIds = (model?: Autodesk.Viewing.Model) => {
  if (!model) model = getCurrentViewer()?.model;
  if (!model) return [];
  const instanceTree = model.getData().instanceTree!;

  return Object.keys(instanceTree.nodeAccess?.dbIdToIndex || {}).map(function (
    id
  ) {
    return parseInt(id);
  });
};

export const getAECData = async (urn: string, shouldCache = false) => {
  const { data } = await forgeApi.getAECByVersionId({
    projectId: "null",
    versionId: encodeURIComponent(urn),
    shouldCache,
  });

  return data;
};

const BATCH_DBIDS_SIZE = 4000;

async function postRetry(
  url: string,
  options: any,
  delay: number,
  tries: number
): Promise<any> {
  const result = await axiosECS.post(url, options);
  if (!result) {
    const triesLeft = tries - 1;
    if (!triesLeft) {
      return;
    }
    await wait(delay);
    logDev(triesLeft);

    return await postRetry(url, options, delay, triesLeft);
  }

  return result;
}

export const getDbIdProperties = async (
  dbIds: number[],
  bimFileId: string,
  version: string,
  bimGuid?: string,
  keys?: string[]
) => {
  const length = Math.ceil(dbIds.length / BATCH_DBIDS_SIZE);
  const promiseArray = [];
  for (let i = 0; i < length; i++) {
    const itemPuts = dbIds.slice(
      i * BATCH_DBIDS_SIZE,
      Math.min(i * BATCH_DBIDS_SIZE + BATCH_DBIDS_SIZE, dbIds.length)
    );
    promiseArray.push(
      postRetry(
        `/v1/forge/projects/null/bims/version/${encodeURIComponent(
          `${bimFileId}?version=${version}`
        )}/properties`,
        {
          keys: keys || [
            "Reference Level",
            "Level",
            "System Name",
            "記号",
            "System Type",
            "Type Name",
            "ファンの種類",
            "積算_施工区分",
            "符号",
            "形式",
            "Size",
            "Design Option",
            "ダクト径_半径",
            "風量",
            "デザイン オプション",
          ],
          dbIds: itemPuts,
          bimGuid: bimGuid || "all",
        },
        500,
        3
      )
    );
  }

  return (await Promise.all(promiseArray))
    .map((item) => item?.data?.data)
    .flat(1);
};

const handleFetchForgeData = async ({
  fileName,
  level,
  shouldCache,
}: {
  fileName: string;
  level?: string;
  shouldCache?: boolean;
}) => {
  try {
    const forgeData = await getForgeData(
      {
        fileName,
        level,
        shouldCache,
      },
      {
        headers: {
          isIgnoreShowMessageError: true,
        },
      }
    );
    if (!forgeData?.data) {
      logError("not have data", fileName, level);

      return;
    }
    const bufferDefault = new Uint8Array(0);
    //@ts-ignore
    const arrayBuffer = await fetch(forgeData.data)
      .then((res) => res.arrayBuffer())
      .catch(() => bufferDefault);
    const textData = ungzip(arrayBuffer, { to: "string" });

    return JSON.parse(textData);
  } catch (err) {
    logError(err);

    return;
  }
};

export const getDataSpace = async ({
  bimFileId,
  version,
  shouldCache,
  level,
}: {
  bimFileId: string;
  version?: string;
  level?: string;
  shouldCache?: boolean;
}): Promise<{ spaces: Space[] } | undefined> => {
  if (!version) {
    return;
  }
  const fileName = `f-spaces-${encodeURIComponent(bimFileId)}-v${version}.json`;

  return await handleFetchForgeData({
    fileName,
    level,
    shouldCache,
  });
};

export const getDataFamilyInstance = async ({
  bimFileId,
  version,
  shouldCache,
  level,
}: {
  bimFileId: string;
  version?: string;
  level?: string;
  shouldCache?: boolean;
}): Promise<
  { familyInstances: { [key: string]: FamilyInstanceDTO } } | undefined
> => {
  if (!version) {
    return;
  }
  const fileName = `f-data-${encodeURIComponent(bimFileId)}-v${version}.json`;

  return await handleFetchForgeData({
    fileName,
    level,
    shouldCache,
  });
};

export const getPropertiesByDbIds = async (
  dbIds: number[]
): Promise<Autodesk.Viewing.PropertyResult[]> => {
  const viewer = getCurrentViewer();
  if (!viewer) {
    return [];
  }

  return new Promise((resolve, reject) => {
    viewer.model.getBulkProperties(
      dbIds,
      { propFilter: ["externalId"], ignoreHidden: true },
      (data) => {
        resolve(data);
      },
      () => {
        reject("cannot load properties");
      }
    );
  });
};

export const getLevelOfPosition = (position: Vector3 | THREE.Vector3) => {
  const levels = store.getState().forgeViewer.levels || [];
  const level = levels.find(
    (level: any) => level.zMin <= position.z && position.z <= level.zMax
  );

  return level?.label;
};

export const handleSetMapDbIdAndExternalId = async () => {
  const x = performance.now();
  let allDbIds = getAllDbIds();
  try {
    const instancesProperties = await getPropertiesByDbIds(allDbIds);
    console.log(performance.now() - x, `Load ${allDbIds.length} externalId`);

    allDbIds = [];
    const mapExternalId: { [key: string]: number } = {};
    for (const instance of instancesProperties) {
      mapExternalId[instance.externalId!] = instance.dbId;
    }
    setMapExternalId(mapExternalId);

    return true;
  } catch (err) {
    logError(err);

    return false;
  }
};

export const getAllMappingExternalId = async (): Promise<
  { [key: string]: number } | undefined
> => {
  const viewer = getCurrentViewer();
  if (!viewer) {
    return;
  }

  return new Promise((res) => {
    viewer.model.getExternalIdMapping(
      (data) => {
        res(data as any);
      },
      () => {
        logDev("fetch mapExternalId failed");
        res(undefined);
      }
    );
  });
};

export const getExternalIdByDbId = async (
  dbId: number
): Promise<string | undefined> => {
  const viewer = getCurrentViewer();
  if (!viewer) {
    return;
  }

  return new Promise((resolve) => {
    viewer.model.getProperties(
      dbId,
      (data) => {
        resolve(data?.externalId);
      },
      () => {
        resolve(undefined);
      }
    );
  });
};
