import { Box, Image } from "@chakra-ui/react";
import CrossLine from "components/editor-builder/component-preview/TableComponentPreview/CrossLine";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import InspectionImage from "components/ui/InspectionImage";
import { Blackboard } from "interfaces/models/blackboard";
import { iBlackboardTemplateProps } from "interfaces/models/documentTemplate";

const ImagePresigned = withPresignedUrl(Image);

interface Props {
  imageSrc?: string;
  isShowBlackboard?: boolean;
  blackboardImagePosition: any;
  blackboard: Blackboard;
  blackboardTemplateProps: iBlackboardTemplateProps;
  boxLoadingWidth?: any;
  boxLoadingHeight?: any;
}

const ImageWithBlackboard = ({
  imageSrc,
  isShowBlackboard,
  blackboardImagePosition,
  blackboard,
  blackboardTemplateProps,
  boxLoadingWidth,
  boxLoadingHeight,
}: Props) => {
  const blackboardTemplateDetail = blackboardTemplateProps?.blackboardTemplate;
  const isShowBlackboardWithImage =
    (isShowBlackboard ?? true) && imageSrc && !!blackboardTemplateDetail?.id;

  return (
    <Box
      className="disalbe-br mceNonEditable"
      style={{
        height: "100%",
        width: "100%",
        margin: 0,
        padding: "0",
        overflow: "hidden",
      }}
    >
      {isShowBlackboardWithImage ? (
        <InspectionImage
          src={imageSrc}
          positionBlackBoard={
            Array.isArray(blackboardImagePosition)
              ? blackboardImagePosition ?? []
              : []
          }
          blackBoard={blackboard}
          styleImage={{
            maxWidth: "100%",
            height: "auto",
          }}
          blackboardTemplateDetail={blackboardTemplateDetail}
          blackboardTemplateProps={blackboardTemplateProps}
        />
      ) : (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          {imageSrc ? (
            <ImagePresigned
              style={{
                maxHeight: "99%",
                maxWidth: "100%",
                objectFit: "contain",
              }}
              crossOrigin="anonymous"
              className="blackboard-image"
              boxLoadingWidth={boxLoadingWidth}
              boxLoadingHeight={boxLoadingHeight}
              src={imageSrc}
            />
          ) : (
            <CrossLine />
          )}
        </Box>
      )}
    </Box>
  );
};

export default ImageWithBlackboard;
